import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import {PageProps} from "gatsby";
import styled from 'styled-components';
import {Palette} from "../constants";


export default function Index(props: PageProps) {
    return <StyledLayout {...props}>
        <div id={"trysomething"}/>
        <Container>
            <div className={'hero'}>
                Great!<br />
                Let's try something...
            </div>
            <div className={'engagement'}>
                <pre>TBD</pre>
            </div>
        </Container>
    </StyledLayout>
}

const StyledLayout = styled(Layout)`
  background-color: #${Palette.MaximumYellowRed.hex};      
`;

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  text-align: center;
  
  .hero {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .
`;
